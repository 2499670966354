import React from 'react'
import styled from 'styled-components'

import Layout from '../components/layout'
import SEO from '../components/seo'
import { ContentContainer, Row } from '../components/utils'
import { PageTitle, Image, FancyText } from '../components/common'

import { useAboutData } from '../hooks'

const ProfilePictureContainer = styled.div`
  float: right;
  clear: right;
  width: 300px;
  margin: 0px 0px 20px 20px;

  @media (max-width: ${props => props.theme.breakpoints.lg}) {
    max-width: 200px;
    margin: 0px 0px 10px 10px;
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    max-width: 150px;
    margin: 0px 0px 5px 5px;
  }
`

const ImageRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: ${props => props.theme.spacing.md} 0;

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    padding: ${props => props.theme.spacing.xs} 0;
  }

  .gatsby-image-wrapper {
    width: calc((100% - 150px) / 3);

    @media (max-width: ${props => props.theme.breakpoints.sm}) {
      width: calc((100% - 50px) / 3);
    }
  }
`

const AboutPage = ({ location }) => {
  const { aboutData } = useAboutData()
  return (
    <Layout location={location}>
      <SEO
        title="About"
        description="At Hamilton Massage Company I provide massage therapy treatments designed to suit your individual needs."
      />
      <PageTitle title="About" />
      <ContentContainer py="md">
        <Row col={1}>
          <h2>{aboutData.aboutCompanyTitle}</h2>
        </Row>
        <ProfilePictureContainer>
          <Image fluid={aboutData.portrait.asset.fluid} alt={aboutData.altText} style={{ borderRadius: '50%' }} />
        </ProfilePictureContainer>
        <FancyText node={({ node }) => node} blocks={aboutData._rawAboutCompanyText} />
        <h3 css="padding-top: 50px">{aboutData.aboutTherapistTitle}</h3>
        <FancyText node={({ node }) => node} blocks={aboutData._rawAboutTherapistText} />
        <ImageRow>
          {aboutData.images.map((image, i) => (
            <Image
              key={i}
              fluid={image.image.asset.fluid}
              alt={image.altText}
              css={`
                margin-bottom: 30px;
              `}
            />
          ))}
        </ImageRow>
      </ContentContainer>
    </Layout>
  )
}

export default AboutPage
